<template>
    <div>
      <!-- 面包屑 begin -->
      <eup-crumbs icon="Money" firstCrumbs="资金管理" secondCrumbs="提现记录" />
      <!-- 面包屑 end -->
      <!-- 内容区域 begin -->
      <div class="container">
          <div class="handle-box">
            <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:fund:fund:getwithdrawpage`])">
              <el-form-item label="用户名">
                <el-select 
                  v-model="state.query.userId" 
                  filterable 
                  placeholder="请选择"
                  :filter-method="filterHandle"
                  :clearable="true"
                  class="handle-select mr10"
                  >
                  <el-option
                    v-for="item in state.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商户提现单号">
                <el-input v-model="state.query.withdrawOutTradeNo" placeholder="商户提现单号" ></el-input>
              </el-form-item>
              <!-- <el-form-item label="交易开始时间">
                <el-date-picker v-model="state.query.startDate" type="date" placeholder="选择日期" :disabledDate="disabledDate1">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="交易结束时间">
                <el-date-picker v-model="state.query.endDate" type="date" placeholder="选择日期" :disabledDate="disabledDate2">
                </el-date-picker>
              </el-form-item> -->
              <el-form-item label="时间选择">
              <el-date-picker
                v-model="state.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              </el-form-item>
              <el-form-item>
              <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table
              :data="state.tableData"
              border
              highlight-current-row
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              v-loading="state.loading"
              >
              <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
              <el-table-column prop="transferOutTradeNo" label="商户转账单号" align="center" min-width="180px"></el-table-column>
              <el-table-column prop="transferTradeNo" label="平台转账单号" align="center" min-width="180px"></el-table-column>
              <el-table-column prop="transferTypeDescribe" label="转账类型" min-width="90" align="center"></el-table-column>
              <el-table-column prop="fundPlatformDescribe" label="转账平台" min-width="90" align="center"></el-table-column>
              <el-table-column prop="transferAmount" label="转账金额" min-width="90" align="center"></el-table-column>
              <el-table-column prop="transferCharge" label="手续费" min-width="80" align="center"></el-table-column>
              <el-table-column prop="actualTransferAmount" label="实际转账金额" min-width="110" align="center"></el-table-column>
              <el-table-column prop="transferDate" label="转账时间" min-width="160" align="center"></el-table-column>
              <el-table-column prop="transferStatuseDescribe" label="转账状态" min-width="90" align="center"></el-table-column>
              <el-table-column prop="transferFinishDate" label="到账时间" min-width="180" align="center"></el-table-column>
              <el-table-column label="操作" min-width="160" align="center" fixed="right">
                <template #default="scope">
                    <el-button
                        type="text"
                        icon="Check"
                        @click="handleClickDetails(scope.row)"
                    >查看详情</el-button>
                </template>
              </el-table-column>
          </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
      </div>
      <!-- 内容区域 end -->
      <!-- 详情窗口 begin -->
      <el-dialog 
        title="充值详情" 
        v-model="state.detailsVisible"
        width="60%"
        >
        <div class="home-container">
        <el-form
          :model="state.detailsForm"
          label-width="80px"
          :inline="false"
          >
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="商户转账单号：" prop="transferOutTradeNo"  label-width="100px">
                   <input v-model="state.detailsForm.rechargeOrderNo" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="平台转账单号：" prop="transferTradeNo"  label-width="100px">
                   <input v-model="state.detailsForm.transferTradeNo" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账类型：" prop="transferTypeDescribe"  label-width="100px">
                   <input v-model="state.detailsForm.transferTypeDescribe" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账平台：" prop="fundPlatformDescribe"  label-width="100px">
                   <input v-model="state.detailsForm.fundPlatformDescribe" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账说明：" prop="transferDescription"  label-width="100px">
                   <input v-model="state.detailsForm.transferDescription" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账金额：" prop="transferAmount"  label-width="100px">
                   <input v-model="state.detailsForm.transferAmount" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账手续费：" prop="transferCharge"  label-width="100px">
                   <input v-model="state.detailsForm.transferCharge" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="实际转账金额：" prop="actualTransferAmount"  label-width="100px">
                   <input v-model="state.detailsForm.actualTransferAmount" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账时间：" prop="transferDate"  label-width="100px">
                   <input v-model="state.detailsForm.transferDate" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="到账时间：" prop="transferFinishDate"  label-width="100px">
                   <input v-model="state.detailsForm.transferFinishDate" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="转账状态：" prop="transferStatuseDescribe"  label-width="100px">
                   <input v-model="state.detailsForm.transferStatuseDescribe" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="收款账号：" prop="accountNo"  label-width="100px">
                   <input v-model="state.detailsForm.accountNo" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="收款姓名：" prop="accountName"  label-width="100px">
                   <input v-model="state.detailsForm.accountName" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="开户行：" prop="bankName"  label-width="100px">
                   <input v-model="state.detailsForm.bankName" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="备注：" prop="remark"  label-width="100px">
                   <input v-model="state.detailsForm.remark" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
          </el-row>
        </el-form>
  </div>
      </el-dialog>
      <!-- 详情窗口 end -->
    </div>
</template>
<script>
import {reactive,onMounted,ref} from "vue"
import { ElMessage } from 'element-plus'
import { GetWithdrawPage} from "@/serviceApi/fund/withdraw";
import { GetSelectList} from "@/serviceApi/user/userList";
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"
import { elConfirmDialog, formatDate,returnFloat, IsNullOrEmpty } from "@/common/js/comm";

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
    name: "Recharge",
    components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },
    setup(){
        const state = reactive({
          VIEW_VERSION: VIEW_VERSION,
            query: {
              "userId": "",
              "withdrawOutTradeNo": "",
            },
            time: "",
            pageIndex: 1,
            pageSize: 10,
            tableData: [],
            multipleSelection: [],
            pageTotal: 0,
            detailsForm: {},
            dynamicFilter:{},
            loading: false,
            detailsVisible: false,
            options: [],//用户下拉
            rechargeStatus:[
              {value: 0, label:"全部"},
              {value: 1, label:"待支付"},
              {value: 2, label:"已支付"},
              {value: 3, label:"已关闭"}
            ]
        });
        onMounted(()=>{
          // getData();
        })
        //获取表单信息
        const getData=()=>{
            var sttime = "";
            var entime = "";
            if (state.time == null) {
            } else {
              sttime = formatDate(state.time[0]);
              entime = formatDate(state.time[1]);
            }
            if (sttime == "NaN-NaN-NaN NaN:NaN:NaN") {
              sttime = "";
            }
            if (entime == "NaN-NaN-NaN NaN:NaN:NaN") {
              entime = "";
            }
            var params = {
                "currentPage": state.pageIndex,
                "pageSize": state.pageSize,
                "filter.userId": state.query.userId,
                "filter.withdrawOutTradeNo": state.query.withdrawOutTradeNo,
                "filter.startDate": sttime,
                "filter.endDate":entime,
                "dynamicFilter": state.dynamicFilter
            }
            if (state.query.userId == ""){
              ElMessage.warning("请选择要查询的用户！");
              return;
            }
            state.loading = true;
            GetWithdrawPage(params).then(function(res){
                if(res.code == 1){
                state.pageTotal = res.data.total;//初始化列表数据总数
                state.tableData = res.data.list;
                //添加num序号字段
                state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
                } else {
                ElMessage.error(res.msg);   
                }
                state.loading = false;
            });
        }
        // 多选操作
       const handleSelectionChange =(val)=> {
            state.multipleSelection = val;
        }
        // 触发搜索按钮
        const handleSearch=()=> {
            state.pageIndex = 1
            getData();
        }

        //充值状态文本格式化
        const rechargeStatusFormat = ((row) =>{
          let resp = "";
          state.rechargeStatus.forEach(item =>{
            if (row.rechargeStatus==item.value && row.rechargeStatus != 0) {
              resp = item.label;
              return false;
            }
          });
          return resp;
        });
        //详情
        const handleClickDetails = ((row) =>{
          state.detailsVisible  = true;
          let strJson = JSON.stringify(row);
          state.detailsForm = JSON.parse(strJson);

          //充值状态
          let rechargeStatus = "";
          state.rechargeStatus.forEach(item=>{
            if (row.rechargeStatus == item.value && row.rechargeStatus != 0){
              rechargeStatus = item.label;
              return false;
            }
          });
          state.detailsForm.rechargeStatus=rechargeStatus;
        });
        //交易日期选择范围
        const disabledDate1= (time) => {
          if (state.query.endDate != "" && state.query.endDate != null && state.query.endDate != undefined) {
            return time.getTime() > state.query.endDate;
          } 
          return time.getTime() > Date.now();
        }
        const disabledDate2= (time) => {
          if (state.query.startDate != "" && state.query.startDate != null && state.query.startDate != undefined){
            return time.getTime() < state.query.startDate || time.getTime() > Date.now();
          }
          return time.getTime() > Date.now();
        }
        //模糊查询api
        const filterHandle = ((value)=>{
          let params = {
            userName: value
          }
          if (params.userName.trim() == ""){
            state.options = [];
            return;
          }          
          if (state.timeoutId){
            clearTimeout(state.timeoutId);
            state.timeoutId = null;
          }
          state.timeoutId = setTimeout(()=>{
            GetSelectList(params).then(function(res){
              if(res.code == 1){
                state.options = [];
                if (res.data.length > 0){
                  res.data.forEach((item)=>{
                    state.options.push({
                      value: item.userId,
                      label: item.userName
                    });
                  });
                }
              } else {
                ElMessage.error(res.msg);   
              }
              state.loading = false;
            });
          }, 500);
        });

        /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }
        return {
            state,
            handleSelectionChange,
            handleSearch,
            handleClickDetails,
            disabledDate1,   
            disabledDate2,  
            filterHandle,
            rechargeStatusFormat,
            getData,
            resPageData
        }
    }
};
</script>
<style scoped>
</style>

