<template>
    <div>
      <!-- 面包屑 begin -->
      <eup-crumbs icon="Sell" firstCrumbs="支付管理" secondCrumbs="退款记录" />
      <!-- 面包屑 end -->
      <!-- 内容区域 begin -->
      <div class="container">
          <div class="handle-box">
            <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:pay:pay:getpagerefund`])">
              <el-form-item label="商户退款单">
                <el-input v-model="state.query.refundOutTradeNo" placeholder="商户退款单号" ></el-input>
              </el-form-item>
              <el-form-item label="商户单号">
                <el-input v-model="state.query.outTradeNo" placeholder="商户单号" ></el-input>
              </el-form-item>
              <el-form-item label="平台退款单">
                <el-input v-model="state.query.refundTradeNo" placeholder="平台退款单号" ></el-input>
              </el-form-item>
              <el-form-item label="退款状态">
                <el-select v-model="state.query.refundStatus" placeholder="退款状态" class="handle-select mr10">
                  <el-option v-for="item in state.refundStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="回调状态">
                <el-select v-model="state.query.isCallBack" placeholder="回调状态" class="handle-select mr10">
                  <el-option v-for="item in state.isCallBack" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="退款平台">
                <el-select v-model="state.query.fundPlatform" placeholder="退款平台" class="handle-select mr10">
                  <el-option v-for="item in state.fundPlatform" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间选择">
              <el-date-picker
                v-model="state.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              </el-form-item>
              <el-form-item>
              <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table
              :data="state.tableData"
              border
              highlight-current-row
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              v-loading="state.loading"
              >
              <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
              <el-table-column prop="refundOutTradeNo" label="商户退款单号" align="center" min-width="182px"></el-table-column>
              <el-table-column prop="outTradeNo" label="商户单号" align="center" min-width="182px"></el-table-column>
              <el-table-column prop="refundTradeNo" label="平台退款单号" align="center" min-width="160px"></el-table-column>
              <el-table-column prop="tradeNo" label="平台单号" align="center" min-width="160px"></el-table-column>
              <el-table-column prop="fundPlatform" label="支付平台" min-width="90" align="center" :formatter="fundPlatformFormat"></el-table-column>
              <el-table-column prop="payAmount" label="支付金额"  min-width="90" align="center"></el-table-column>
              <el-table-column prop="refundAmount" label="退款金额" min-width="90" align="center"></el-table-column>
              <el-table-column prop="actualRefundAmount" label="实际退款金额"  min-width="110" align="center"></el-table-column>
              <el-table-column prop="refundStatus" label="退款状态" min-width="90" align="center" :formatter="refundStatusFormat"></el-table-column>
              <el-table-column prop="isCallBack" label="回调状态" min-width="90" :formatter="isCallBackFormat" align="center"></el-table-column>
              <el-table-column prop="refundDate" label="退款时间" align="center" min-width="160px"></el-table-column>
              <el-table-column prop="createDate" label="创建时间"  align="center" min-width="160px"></el-table-column>
              <el-table-column label="操作" min-width="160" align="center" fixed="right">
                <template #default="scope">
                    <el-button
                        type="text"
                        icon="Check"
                        @click="handleClickDetails(scope.row)"
                    >查看详情</el-button>
                </template>
              </el-table-column>
          </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
      </div>
      <!-- 内容区域 end -->
      <!-- 详情窗口 begin -->
      <el-dialog 
        title="退款详情" 
        v-model="state.detailsVisible"
        width="70%"
        >
        <div class="home-container">
        <el-form
          :model="state.detailsForm"
          label-width="80px"
          :inline="false"
          >
          <el-row>
                  <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="商户退款单号" prop="sequence"  label-width="100px">
                   <input v-model="state.detailsForm.refundOutTradeNo" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>   
                 <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="平台退款单号" prop="sequence"  label-width="100px">
                    <input v-model="state.detailsForm.refundTradeNo" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col>  
                  <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="商户单号" prop="sequence"  label-width="100px">
                   <input v-model="state.detailsForm.outTradeNo" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col> 
                  <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="平台单号" prop="sequence"  label-width="100px">
                  <input v-model="state.detailsForm.tradeNo" class="inputin" :disabled="true" /> 
                  </el-form-item>
              </el-col> 
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="支付金额" prop="sequence"  label-width="100px">
                    <input v-model="state.detailsForm.payAmount" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col> 
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="实际退款金额" prop="sequence"  label-width="100px">
                  <input v-model="state.detailsForm.actualRefundAmount" class="inputin" :disabled="true" />  
                  </el-form-item>
              </el-col> 
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款手续费" prop="sequence"  label-width="100px">
                  <input v-model="state.detailsForm.refundCharge" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col> 
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款平台" prop="sequence"  label-width="100px">
                 <input v-model="state.detailsForm.fundPlatform" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col> 
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款说明" prop="sequence"  label-width="100px">
                 <input v-model="state.detailsForm.refundDescription" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col> 
               <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款时间" prop="sequence"  label-width="100px">
                 <input v-model="state.detailsForm.refundDate" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col> 
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款状态" prop="sequence"  label-width="100px">
                <input v-model="state.detailsForm.refundStatus" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col>
               <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款状态码" prop="sequence"  label-width="100px">
                <input v-model="state.detailsForm.refundStatusCode" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="退款状态消息" prop="sequence"  label-width="100px">
                <input v-model="state.detailsForm.refundStatusMsg" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="是否回写" prop="sequence"  label-width="100px">
                <input v-model="state.detailsForm.isCallBack" class="inputin" :disabled="true" />
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item label="备注" prop="sequence"  label-width="100px">
               <input v-model="state.detailsForm.remark" class="inputin deunh" :disabled="true" />
                  </el-form-item>
              </el-col>
          </el-row>
        </el-form>
  </div>
      </el-dialog>
      <!-- 详情窗口 end -->
    </div>
</template>
<script>
import {reactive,onMounted,ref} from "vue"
import { ElMessage } from 'element-plus'
import { GetPageRefund} from "@/serviceApi/pay/refundRecord";
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"
import { elConfirmDialog, formatDate,returnFloat, IsNullOrEmpty } from "@/common/js/comm";

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
    name: "RefundRecord",
    components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },
    setup(){
        const state = reactive({
          VIEW_VERSION: VIEW_VERSION,
            query: {
              "refundOutTradeNo": "",
              "outTradeNo": "",
              "refundTradeNo": "",
              "refundStatus": 0,
              "isCallBack": 0,
              "fundPlatform": 0
            },
            time: "",
            pageIndex: 1,
            pageSize: 10,
            tableData: [],
            multipleSelection: [],
            pageTotal: 0,
            detailsForm: {},
            dynamicFilter:{},
            loading: false,
            //退款状态
            refundStatus: [
              {value: 0, label: '全部'},
              {value: 1, label: '待退款'},
              {value: 2, label: '已退款'},
              {value: 3, label: '已关闭'},
              {value: 4, label: '退款失败'},
              {value: 9, label: '退款中'}
            ],
            //回调状态
            isCallBack: [
              { value: 0, label: '全部' },
              { value: 1, label: '未回调' },
              { value: 2, label: '已回调' },
              { value: 3, label: '回调失败' }
            ],
            detailsVisible: false,
            //资金平台
            fundPlatform: [
              { value: 0, label: '全部' },
              {value: 1, label: '银联' },
              {value: 11, label: '银联企业网银' },
              {value: 2, label: '支付宝' },
              {value: 3, label: '微信' },
              {value: 4, label: '余额' },
              {value: 9, label: "线下支付"}
            ],
            //支付宝交易状态
            alipayTradeStatus:[
              {value: 1, label: '待支付' },
              {value: 2, label: '已支付' },
              {value: 3, label: '已关闭' }
            ],
            //担保交易状态
            securedTradeStatus:[
              {value: 1, label: '非担保交易' },
              {value: 2, label: '交易中' },
              {value: 4, label: '已完成' },
              {value: 9, label: '已关闭' }
            ]
        });
        onMounted(()=>{
            getData();
        })
        //获取表单信息
        const getData=()=>{
            var sttime = "";
            var entime = "";
            if (state.time == null) {
            } else {
              sttime = formatDate(state.time[0]);
              entime = formatDate(state.time[1]);
            }
            if (sttime == "NaN-NaN-NaN NaN:NaN:NaN") {
              sttime = "";
            }
            if (entime == "NaN-NaN-NaN NaN:NaN:NaN") {
              entime = "";
            }
            var params = {
              "currentPage": state.pageIndex,
              "pageSize": state.pageSize,
              "filter.refundOutTradeNo": state.query.refundOutTradeNo,
              "filter.outTradeNo": state.query.outTradeNo,
              "filter.refundTradeNo": state.query.refundTradeNo,
              "filter.refundStatus": state.query.refundStatus=='0'? null:state.query.refundStatus,
              "filter.isCallBack": state.query.isCallBack=='0'? null:state.query.isCallBack,
              "filter.startDate": sttime,
              "filter.endDate":entime,
              "dynamicFilter": state.dynamicFilter,
              "filter.fundPlatform": state.query.fundPlatform == '0' ? null : state.query.fundPlatform
            }
            state.loading = true;
            GetPageRefund(params).then(function(res){
                if(res.code == 1){
                state.pageTotal = res.data.total;//初始化列表数据总数
                state.tableData = res.data.list;
                //添加num序号字段
                state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
                } else {
                ElMessage.error(res.msg);   
                }
                state.loading = false;
            });
        }
        // 多选操作
       const handleSelectionChange =(val)=> {
            state.multipleSelection = val;
        }
        // 触发搜索按钮
        const handleSearch=()=> {
            state.pageIndex = 1
            getData();
        }

        //退款平台文本格式化
        const fundPlatformFormat = ((row)=>{
          let resp = "";
          state.fundPlatform.forEach(item =>{
            if (row.fundPlatform==item.value && row.fundPlatform != 0) {
              resp = item.label;
              return false;
            }
          });
          return resp;
        });
        //退款状态文本格式化
        const refundStatusFormat = ((row) =>{
          let resp = "";
          state.refundStatus.forEach(item =>{
            if (row.refundStatus==item.value && row.refundStatus != 0) {
              resp = item.label;
              return false;
            }
          });
          return resp;
        });
        //回调状态文本格式化
        const isCallBackFormat = ((row)=>{
          let resp = "";
          state.isCallBack.forEach(item =>{
            if (row.isCallBack==item.value && row.isCallBack != 0) {
              resp = item.label;
              return false;
            }
          });
          return resp;
        });
        //详情
        const handleClickDetails = ((row) =>{
          state.detailsVisible  = true;
          let strJson = JSON.stringify(row);
          state.detailsForm = JSON.parse(strJson);
          //退款平台
          let fundPlatform = "";
          state.fundPlatform.forEach(item=>{
            if (row.fundPlatform == item.value && row.fundPlatform != 0){
              fundPlatform = item.label;
              return false;
            }
          });
          state.detailsForm.fundPlatform=fundPlatform;
          //退款状态
          let refundStatus = "";
          state.refundStatus.forEach(item=>{
            if (row.refundStatus == item.value && row.refundStatus != 0){
              refundStatus = item.label;
              return false;
            }
          });
          state.detailsForm.refundStatus=refundStatus;
          //回调状态
          let isCallBack = "";
          state.isCallBack.forEach(item=>{
            if (row.isCallBack == item.value && row.isCallBack != 0){
              isCallBack = item.label;
              return false;
            }
          });
          state.detailsForm.isCallBack=isCallBack;
        });
        //交易日期选择范围
        const disabledDate1= (time) => {
          if (state.query.endDate != "" && state.query.endDate != null && state.query.endDate != undefined) {
            return time.getTime() > state.query.endDate;
          } 
          return time.getTime() > Date.now();
        }
        const disabledDate2= (time) => {
          if (state.query.startDate != "" && state.query.startDate != null && state.query.startDate != undefined){
            return time.getTime() < state.query.startDate || time.getTime() > Date.now();
          }
          return time.getTime() > Date.now();
        }

        /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }
        return {
            state,
            handleSelectionChange,
            handleSearch,
            fundPlatformFormat,
            refundStatusFormat,
            isCallBackFormat,
            handleClickDetails,
            disabledDate1,   
            disabledDate2,
            getData,
            resPageData
        }
    }
};
</script>
<style scoped>
</style>

