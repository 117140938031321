<template>
    <div>
      <!-- 面包屑 begin -->
      <eup-crumbs icon="Money" firstCrumbs="资金管理" secondCrumbs="余额记录" />
      <!-- 面包屑 end -->
      <!-- 内容区域 begin -->
      <div class="container">
          <div class="handle-box">
            <el-form ref="state.query" :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:fund:fund:getbalancepage`, `api${state.VIEW_VERSION}:fund:fund:getfrozenbalancepage`])">
              <el-form-item label="用户名">
                <el-select 
                  v-model="state.query.userId" 
                  filterable 
                  placeholder="请选择"
                  remote
                  :remote-method="filterHandle"
                  :clearable="true"
                   class="handle-select mr10"
                  >
                  <el-option
                    v-for="item in state.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商户单号">
                <el-input v-model="state.query.outTradeNo" placeholder="商户单号" ></el-input>
              </el-form-item>
              <el-form-item label="时间选择">
              <el-date-picker
                v-model="state.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              </el-form-item>
              <el-form-item label="账号类型">
                <el-select v-model="state.fundRecordTypeQuery" placeholder="账号类型" class="handle-select mr10" @change="fundTypeChangeHandle">
                  <el-option v-for="item in state.fundRecordType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                 <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table
              :data="state.tableData"
              border
              highlight-current-row
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              v-loading="state.loading"
              >
              <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
              <el-table-column prop="fundType" label="资金类型" min-width="160" align="center" :formatter="fundTypeFormat"></el-table-column>
              <el-table-column prop="changeAmount" label="变动余额" min-width="160" align="center">
                <template #default="{row}">
                  <el-tag 
                    :type="
                      row.changeAmount < 0
                        ? 'success'
                        : row.changeAmount > 0
                        ? 'danger'
                        : ''
                    "
                    disable-transitions
                    >{{
                      row.changeAmount > 0
                        ? "+" +maging(row.changeAmount)
                        : row.changeAmount < 0
                        ? "-" + maging(row.changeAmount)
                        : "0"
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="afterAmount" label="变动后余额" min-width="160" align="center"></el-table-column>
              <el-table-column prop="outTradeNo" label="商户单号" min-width="180" align="center"></el-table-column>
              <el-table-column prop="recordDate" label="交易时间" min-width="160" align="center"></el-table-column>
              <el-table-column label="操作" min-width="180" align="center" fixed="right">
                <template #default="scope">
                    <el-button
                        type="text"
                        icon="Check"
                        @click="handleClickDetails(scope.row)"
                    >查看详情</el-button>
                </template>
              </el-table-column>
          </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="getData"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
      </div>
      <!-- 内容区域 end -->
      <!-- 详情窗口 begin -->
      <el-dialog 
        title="余额详情" 
        v-model="state.detailsVisible"
        width="70%"
        >
        <div class="home-container">
          <el-form
            :model="state.detailsForm"
            label-width="80px"
            :inline="false"
            >
            <el-row>
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="资金类型：" prop="fundTypeDescribe"  label-width="100px">
                   <input v-model="state.detailsForm.fundTypeDescribe" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="变动余额：" prop="changeAmount"  label-width="100px">
                   <input v-model="state.detailsForm.changeAmount" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="变动后余额" prop="afterAmount"  label-width="100px">
                   <input v-model="state.detailsForm.afterAmount" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="商户单号：" prop="outTradeNo"  label-width="100px">
                   <input v-model="state.detailsForm.outTradeNo" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="交易时间：" prop="recordDate"  label-width="100px">
                   <input v-model="state.detailsForm.recordDate" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="交易描述：" prop="recordDescription"  label-width="100px">
                   <input v-model="state.detailsForm.recordDescription" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
                            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                  <el-form-item label="备注：" prop="remark"  label-width="100px">
                   <input v-model="state.detailsForm.remark" class="inputin" :disabled="true" />   
                  </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-dialog>
      <!-- 详情窗口 end -->
    </div>
</template>
<script>
import {reactive,onMounted,ref} from "vue"
import { ElMessage } from 'element-plus'
import { GetBalancePage, GetFrozenBalancePage} from "@/serviceApi/fund/balance";
import { GetSelectList} from "@/serviceApi/user/userList";
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"
import { returnFloat, formatDate } from "@/common/js/comm";

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
    name: "Balance",
    components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },
    setup(){
        const state = reactive({
           VIEW_VERSION: VIEW_VERSION,
            query: {
              "userId": "",
              "fundType": 0,
              "outTradeNo": "",
              // "startDate": "",
              // "endDate": ""
            },
            time: "",
            pageIndex: 1,
            pageSize: 10,
            tableData: [],
            multipleSelection: [],
            pageTotal: 0,
            detailsForm: {},
            dynamicFilter:{},
            loading: false,
            fundRecordType:[
              {value: 110, label: '余额账号'},
              {value: 120, label: '冻结余额账号'},
            ],
            fundRecordTypeQuery: 110,
            //资金记录类型
            fundType: [
              {value: 100, label: '收入'},
              {value: 101, label: '订单收入'},
              {value: 102, label: '充值'},
              {value: 103, label: '退款(收入)'},
              {value: 112, label: '订单返现(用户)'},
              {value: 121, label: '提现/转账失败'},
              {value: 131, label: '保证金转入余额'},
              {value: 200, label: '支出'},
              {value: 201, label: '订单退款'},
              {value: 202, label: '订单支付'},
              {value: 211, label: '订单手续费'},
              {value: 212, label: '订单返现(系统)'},
              {value: 221, label: '余额提现/转账'},
              {value: 300, label: '冻结'},
              {value: 301, label: '订单收入'},
              {value: 311, label: '保证金冻结'},
              {value: 321, label: '提现/转账冻结'},
              {value: 400, label: '解冻'},
              {value: 401, label: '订单收入'},
              {value: 402, label: '订单取消退款'},
              {value: 402, label: '订单退款'},
              {value: 403, label: '保证金解冻'},
              {value: 411, label: '订单取消退款'},
              {value: 421, label: '提现/转账失败解冻'},
              {value: 422, label: '提现/转账成功解冻'}
            ],
            detailsVisible: false,
            options: [],
            timeoutId: null
        });
        onMounted(()=>{
            // getData();
        })
        //获取表单信息
        const getData=()=>{
          // state.query.fundType = state.fundType;
          var sttime = "";
          var entime = "";
          if (state.time == null) {
          } else {
            sttime = formatDate(state.time[0]);
            entime = formatDate(state.time[1]);
          }
          if (sttime == "NaN-NaN-NaN NaN:NaN:NaN") {
            sttime = "";
          }
          if (entime == "NaN-NaN-NaN NaN:NaN:NaN") {
            entime = "";
          }
            var params = {
                "currentPage": state.pageIndex,
                "pageSize": state.pageSize,
                "filter.userId": state.query.userId,
                "filter.fundType": state.query.fundType=='0'? null:state.query.fundType,
                "filter.outTradeNo": state.query.outTradeNo,
                "filter.startDate": sttime,
                "filter.endDate": entime,
                "dynamicFilter": state.dynamicFilter
            }
            if (state.query.userId == ""){
              ElMessage.warning("请选择要查询的用户！");
              return;
            }
            state.loading = true;
            if (state.fundRecordTypeQuery == state.fundRecordType[0].value){//余额账号
              GetBalancePage(params).then(function(res){
                if(res.code == 1){
                  state.pageTotal = res.data.total;//初始化列表数据总数
                  state.tableData = res.data.list;
                  //添加num序号字段
                  state.tableData.forEach((data, i) => {
                      data.num = i + 1;
                  });
                } else {
                ElMessage.error(res.msg);   
                }
                state.loading = false;
              });
            } else {//冻结余额账号
              GetFrozenBalancePage(params).then(function(res){
                if(res.code == 1){
                  state.pageTotal = res.data.total;//初始化列表数据总数
                  state.tableData = res.data.list;
                  //添加num序号字段
                  state.tableData.forEach((data, i) => {
                      data.num = i + 1;
                  });
                } else {
                ElMessage.error(res.msg);   
                }
                state.loading = false;
              });
            }
        }
        // 多选操作
       const handleSelectionChange =(val)=> {
            state.multipleSelection = val;
        }
        // 触发搜索按钮
        const handleSearch=()=> {
            state.pageIndex = 1
            getData();
        }

        //资金类型
        const fundTypeFormat = ((row)=>{
          let resp = "";
          state.fundType.forEach(item =>{
            if (row.fundType==item.value && row.fundType != 0) {
              resp = item.label;
              return false;
            }
          });
          return resp;
        });
        //详情
        const handleClickDetails = ((row) =>{
          state.detailsVisible  = true;
          let strJson = JSON.stringify(row);
          state.detailsForm = JSON.parse(strJson);
          //转账平台
          let fundType = "";
          state.fundRecordType.forEach(item=>{
            if (row.fundType == item.value && row.fundType != 0){
              fundType = item.label;
              return false;
            }
          });
          state.detailsForm.fundType=fundType;
        });

        //交易日期选择范围
        const disabledDate1= (time) => {
          if (state.query.endDate != "" && state.query.endDate != null && state.query.endDate != undefined) {
            return time.getTime() > state.query.endDate;
          } 
          return time.getTime() > Date.now();
        }
        const disabledDate2= (time) => {
          if (state.query.startDate != "" && state.query.startDate != null && state.query.startDate != undefined){
            return time.getTime() < state.query.startDate || time.getTime() > Date.now();
          }
          return time.getTime() > Date.now();
        }

        //模糊查询
        const filterHandle = ((value)=>{
          let params = {
            userName: value
          }
          if (params.userName.trim() == ""){
            state.options = [];
            return;
          }          
          if (state.timeoutId){
            clearTimeout(state.timeoutId);
            state.timeoutId = null;
          }
          state.timeoutId = setTimeout(()=>{
            GetSelectList(params).then(function(res){
              if(res.code == 1){
                state.options = [];
                if (res.data.length > 0){
                  res.data.forEach((item)=>{
                    state.options.push({
                      value: item.userId,
                      label: item.userName
                    });
                  });
                }
              } else {
                ElMessage.error(res.msg);   
              }
              state.loading = false;
            });
          }, 500);
        });
        //切换资金类型
        const fundTypeChangeHandle = ()=>{
          getData();
        };

        /**
         * @description 子组件返回分页数据
         * @author weig
         * @param {Object} obj
         */
        const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }

        const  maging=(val)=>{
          return returnFloat(val)
        }
        return {
            state,
            handleSelectionChange,
            handleSearch,
            handleClickDetails,
            disabledDate1,   
            disabledDate2, 
            fundTypeFormat,
            filterHandle,
            fundTypeChangeHandle,
            getData,
            resPageData,
            maging
        }
    }
};
</script>
<style scoped>
</style>

